import Modal from "react-modal";
import { Container, Contents, ItemLoading, Title } from "./styled";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { XMarkIcon } from "@heroicons/react/24/outline";

import { useEffect, useState } from 'react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { FaceFrownIcon, FaceSmileIcon, FireIcon, HandThumbUpIcon, HeartIcon, PaperClipIcon, } from '@heroicons/react/20/solid'
import { Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'


const customStyles = {
    content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        width: "540px",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        backgroundColor: "transparent",
        padding: "0px",
        margin: "0px",
        borderRadius: "8px",
        border: "none"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    },
};



const ModalFeedResendEmail = (props) => {

    const dispatch = useDispatch()
    const language = useSelector((state) => state.reducerLanguage);
    const { id } = useParams();



    const closeModal = () => {
        props.handleClick()

        setTimeout(() => {
            props.setVisible(false)
            props.goSearch()
            props.setDataModalOrdersCompleted([])
        }, 100);

    }

    useEffect(() => {
        console.log("props selectedOrders", props)
    }, [])



    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
            onRequestClose={() => { }}
        >
            <Container>
                <Title>
                    <div>
                        <label>Reenviando e-mails</label>
                        <XMarkIcon onClick={() => { closeModal() }} style={{ cursor: "pointer" }} width={16} />
                    </div>
                    <label >
                        Aguarde enquanto reenviamos os emails dos pedidos selecionados.
                    </label>
                </Title>
                <Contents>
                    <Feed
                        dataModalOrdersCompleted={props.dataModalOrdersCompleted}
                        selectedOrders={props.selectedOrders}
                        orderLoadingId={props.orderLoadingId}
                    />
                </Contents>
            </Container>

        </Modal >
    )
}

export default ModalFeedResendEmail


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Feed(props) {

    useEffect(() => {
        console.log("props", props)
    }, [props])


    return (
        <>

            {/* {Array(10).fill(
                <ul role="list" className="space-y-6">
                    <li key={"item.id"} className="relative flex gap-x-4">
                        <div className={classNames("index" === "props.selectedOrders.length" - 1 ? 'h-6' : '-bottom-6', 'absolute left-0 top-0 flex w-6 justify-center',)}>
                            <div className="w-px bg-gray-200" />
                        </div>

                        <>
                            <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                {"props.dataModalSincCompleted".includes("item.id") ? (
                                    <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                                ) : (
                                    <ItemLoading active={"props.orderLoadingId" == 'item.id' ? true : false}>
                                        <div className={`h-1.5 w-1.5 rounded-full bg-gray-100 ring-1  ring-${"props.orderLoadingId " == "item.id" ? "green" : "gray"}-300`} />
                                    </ItemLoading>
                                )}
                            </div>
                            <p className="flex-auto py-0.5 text-xs/5 text-gray-500">
                                <span style={{ marginRight: "8px" }} className="font-medium text-gray-900">{"item.name"}</span>

                            </p>
                            <label style={{ fontSize: "9px" }} className="flex-none py-0.5 text-xs/5 text-gray-500">
                                {"props.dataModalSincCompleted.includes(item.id)" ? "Completo" : "props.orderLoadingId" == "item.id" ? "Sincronizando" : "Aguardando"}
                            </label>
                        </>

                    </li>
                </ul>
            )} */}
            <ul role="list" className="space-y-6">
                {props.selectedOrders.map((item, index) => {

                    return (
                        <li key={item.id} className="relative flex gap-x-4">
                            <div className={classNames(index === props.selectedOrders.length - 1 ? 'h-6' : '-bottom-6', 'absolute left-0 top-0 flex w-6 justify-center',)}>
                                <div className="w-px bg-gray-200" />
                            </div>

                            <>
                                <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
                                    {props.dataModalOrdersCompleted.includes(item.id) ? (
                                        <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                                    ) : (
                                        <ItemLoading active={props.orderLoadingId == item.id ? true : false}>
                                            <div className={`h-1.5 w-1.5 rounded-full bg-gray-100 ring-1  ring-${props.orderLoadingId == item.id ? "green" : "gray"}-300`} />
                                        </ItemLoading>
                                    )}
                                </div>
                                <p className="flex-auto py-0.5 text-xs/5 text-gray-500">
                                    <span style={{ marginRight: "8px" }} className="font-medium text-gray-900">{item.email}</span>

                                </p>
                                <label style={{ fontSize: "9px" }} className="flex-none py-0.5 text-xs/5 text-gray-500">
                                    {props.dataModalOrdersCompleted.includes(item.id) ? "Completo" : props.orderLoadingId == item.id ? "Reenviando" : "Aguardando"}
                                </label>
                            </>

                        </li>
                    )
                })}

            </ul>


        </>
    )
}


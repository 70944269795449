import React from 'react'
import { Container, Contents,  } from './styled'
import { Title } from '../lazy-loading/styled'


const LazyLoadingSteps = () => {
  return (
    <Container>
      <Title>
        <div />
        <span />
      </Title>
      <Contents />
    </Container>
  )
}

export default LazyLoadingSteps
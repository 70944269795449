import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { BoxActions, BoxCheck, BoxConfirm, BoxIconConfirm, BoxLoading, BoxOptions, BoxQtd, BoxReviews, BoxTextConfirm, BoxTitleReviews, BoxTitleReviewsAction, BoxTitleReviewsLeft, ButtonMoreReviews, Close, Container, ContainerConfirm, Form, IconSuccess, InputCustom, ItemBoxAction, ItemBoxActionLabel, ItemCheck, ItemOption, ListProducts, Logo, LogoToPlatform, Screen, TextDownload, TextEnd, Title } from './styled';

import LogoLily from "../../../../assets/imgs/Girrafe.svg";
import LogoExcel from "../../../../assets/imgs/icon-excel.png";
import LogoLoox from "../../../../assets/imgs/logo-loox.png";
import LogoRyviu from "../../../../assets/imgs/logo-ryviu.png";
import LogoShopee from "../../../../assets/imgs/logo-modal-shopee.svg";
import { useEffect, useState } from 'react';
import { BsCheck } from 'react-icons/bs';
import CardImportReview from '../card-import-review';

import StarVazia from "../../../../assets/imgs/star-vazia.svg";
import StarCheia from "../../../../assets/imgs/star-cheia.svg";
import StarMeia from "../../../../assets/imgs/star-meia.svg";

import LilyDownloadModelo from "../../../../assets/download/lily-modelo-reviews.xlsx";

import * as XLSX from 'xlsx/xlsx.mjs';
import { getTranslation } from '../../../../translations';
import CardImportProduct from '../card-import-product';
import { Tag } from 'iconsax-react';

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: "white",
        padding: "0px",
        margin: "0px",
        borderRadius: "10px"
    },
    overlay: {
        backgroundColor: "rgba(10,23,55,0.5)",
        zIndex: "99999999"
    }
};





const ModalImportShopeeNew = (props) => {

    const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);
    const productsImport = useSelector(state => state.reducerProductsImport)
    const [productImportSelected, setProductImportSelected] = useState(null)

    const dispatch = useDispatch()


    const goSearchReviewsImportProduct = (product) => {
        if (product == 0) {

            dispatch({
                type: "SAGA_LIST_REVIEWS", payload: {
                    rating: "",
                    status: "all",
                    search: "",
                    perPage: 20,
                    page: reviews.data.meta.current_page + 1,
                    medias: "",
                    productId: productImportSelected.id
                }
            })
        } else {
            dispatch({
                type: "SAGA_LIST_REVIEWS", payload: {
                    rating: "",
                    status: "all",
                    search: "",
                    perPage: 20,
                    page: 1,
                    medias: "",
                    productId: product.id

                }
            })
        }

    }

    const [pageImportReviews, setpageImportReviews] = useState(1)

    const [optionActive, setOptionActive] = useState(null)
    const [checkImages, setCheckImages] = useState(false)
    const [checkImagesOne, setCheckImagesOne] = useState(false)
    const [numberReviews, setnumberReviews] = useState(20)
    const [linkShopee, setLinkShopee] = useState("")

    const [visibleConfirm, setVisibleConfirm] = useState(false)
    const [visibleConfirmDeleteReview, setVisibleConfirmDeleteReview] = useState(false)

    const statusImportShopee = useSelector(state => state.reducerImportShopee)
    const statusImportShopeeMore = useSelector(state => state.reducerImportShopeeMore)
    const statusSaveImportShopee = useSelector(state => state.reducerSaveImportShopee)
    const statusControlSaveImportShopee = useSelector(state => state.reducerControlSaveImportReviews)
    const statusControlImportShopeeMore = useSelector(state => state.reducerControlImportShopeeMore)

    const reviews = useSelector(state => state.reducerReviews)

    const [paginateReviewsIsEnd, setPaginateReviewsIsEnd] = useState(false)
    const [paginateProductsIsEnd, setPaginateRProductsIsEnd] = useState(false)

    const [loadingShopeeText, setLoadingShopeeText] = useState("")
    const [dataShopee, setdataShopee] = useState([])
    const [dataProductsImport, setDataProductsImport] = useState([])
    const [countAlertDelete, setCountAlertDelete] = useState(0)
    const [reviewIdSelected, setReviewIdSelected] = useState(null)

    const screenActive = useSelector(state => state.reducerControlScreenActive)


    const [page, setpage] = useState(1)








    const deleteReviewShopee = (id) => {

        let dataFilter = dataShopee.filter(item => item.id != id);
        setdataShopee(dataFilter)
    }

    const deleteImageReviewShopee = (id, image) => {
        let data = dataShopee.map(item => {
            if (item.id == id) {
                let images = item.images.filter(item => item != image)
                return { ...item, images }
            } else {
                return item
            }
        });
        setdataShopee(data)
    }

    const changeStarReviewShopee = (id, rating) => {
        let data = dataShopee.map(item => {
            if (item.id == id) {
                return { ...item, rating: rating }
            } else {
                return item
            }
        });
        setdataShopee(data)
    }

    const deleteAllImageReviewShopee = () => {
        let data = dataShopee.map(item => {
            return { ...item, images: [] }
        });
        setdataShopee(data)
    }

    const changeNameReviewShopee = (id, name) => {
        let data = dataShopee.map(item => {
            if (item.id == id) {
                return { ...item, name: name }
            } else {
                return item
            }
        });
        setdataShopee(data)
    }

    const changeDateReviewShopee = (id, date) => {
        let data = dataShopee.map(item => {
            if (item.id == id) {
                return { ...item, date: date }
            } else {
                return item
            }
        });
        setdataShopee(data)
    }

    const changeMessageReviewShopee = (id, message) => {
        let data = dataShopee.map(item => {
            if (item.id == id) {
                return { ...item, message: message }
            } else {
                return item
            }
        });
        setdataShopee(data)
    }

    useEffect(() => {
        if (!statusImportShopee.loading && !statusImportShopee.error && statusImportShopee.data.length == 0) {
        } else {
            if (statusImportShopee.loading) {
                setLoadingShopeeText(`Importando ${translation.products.avaliacoes2}...`)
            } else {
                if (statusImportShopee.error) {
                    setLoadingShopeeText("Falhou")
                } else {
                    setLoadingShopeeText(translation.products.sucesso)
                    setdataShopee(statusImportShopee.data)
                    setTimeout(() => {
                        //props.setScreenActive("list-reviews")
                        if (screenActive.screen == "shopee-loading") {
                            dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "list-reviews" } })
                        }
                    }, 500);
                }
            }
        }
    }, [statusImportShopee])

    useEffect(() => {
        if (!productsImport.loading && !productsImport.error && productsImport.data.length == 0) {
        } else {
            if (productsImport.loading) {
                //setLoadingShopeeText("Importando reviews...")
            } else {
                if (productsImport.error) {
                    //setLoadingShopeeText("Falhou")
                } else {
                    //setLoadingShopeeText("Sucesso")
                    setDataProductsImport([...dataProductsImport, ...productsImport.data.data])
                }
            }
        }
    }, [productsImport])

    useEffect(() => {
        console.log("dataProductsImport", dataProductsImport)
    }, [dataProductsImport])


    useEffect(() => {

        if (!reviews.loading && !reviews.error && reviews?.data?.length == 0) {
        } else {
            if (reviews.loading) {
                if (screenActive.screen == "lily-product-data") {
                    dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "shopee-loading" } })
                }
                setLoadingShopeeText(`Importando ${translation.products.avaliacoes2}...`)
            } else {
                if (reviews.error) {
                    setLoadingShopeeText("Falhou")
                } else {
                    setLoadingShopeeText(translation.products.sucesso)
                    setdataShopee([...dataShopee, ...transformReview(reviews.data.data)])
                    if (reviews.data.data.length == 0) {
                        setPaginateReviewsIsEnd(true)
                    }
                    setTimeout(() => {
                        //props.setScreenActive("list-reviews")
                        if (screenActive.screen == "shopee-loading") {
                            dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "list-reviews" } })
                        }
                        if (screenActive.screen == "lily-product-data") {
                            dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "list-reviews" } })
                        }
                    }, 500);
                }
            }
        }
    }, [reviews])

    useEffect(() => {

        if (!statusSaveImportShopee.loading && !statusSaveImportShopee.error && statusSaveImportShopee?.data?.length == 0) {
        } else {
            if (statusSaveImportShopee.loading) {

            } else {
                if (statusSaveImportShopee.error) {
                    setLoadingShopeeText("Falhou.")
                } else {
                    //props.setScreenActive("shopee-end")
                    if (screenActive.screen != "options") {
                        dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "shopee-end" } })
                    }

                }
            }
        }
    }, [statusSaveImportShopee])


    // const closeModal = () => {
    //     props.setVisibleModal(false);
    //     dispatch({
    //         type: "SAGA_LIST_PRODUCTS", payload: {
    //             search: "",
    //             perPage: 20,
    //             page: 1
    //         }
    //     })
    // }

    const closeModal = () => {
        props.setVisibleModal(false)
        //props.setScreenActive("options")
        dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "options" } })
        setdataShopee([])
        setCheckImages(false)
        setCheckImagesOne(false)
        setLinkShopee("")
        setnumberReviews(20)

        dispatch({
            type: "SAGA_LIST_PRODUCTS", payload: {
                search: "",
                perPage: 20,
                page: 1
            }
        })


        dispatch({
            type: "SAGA_LIST_PRODUCTS_IMPORT", payload: {
                search: "",
                perPage: 40,
                page: 1
            }
        })

    }

    const importReviews = () => {
        let aux = linkShopee.split('-i.')[1]
        aux = aux.split('?')[0]

        let shopId = aux.split('.')[0];
        let itemId = aux.split('.')[1];

        dispatch({
            type: "SAGA_IMPORT_SHOPEE_PRODUCTS", payload: {
                shopId: shopId,
                itemId: itemId,
                limit: numberReviews,
                midias: checkImages ? 1 : 0,
                midiasOne: checkImagesOne ? 1 : 0,
                url: linkShopee
            }
        })
    }


    const saveReviews = () => {
        dispatch({
            type: "SAGA_SAVE_IMPORT_SHOPEE_PRODUCTS", payload: {
                reviews: dataShopee,
                product_id: props.item.id
            }
        })
    }


    const pasteUrl = async () => {
        const response = await navigator.clipboard.readText();
        setLinkShopee(response)
    }

    const calcMediaRating = () => {
        //return 5
        let initialValue = 0;
        let sumWithInitial = dataShopee.reduce((accumulator, currentValue) => accumulator + currentValue.rating, initialValue);
        return sumWithInitial / dataShopee.length
    }

    useEffect(() => {
        calcMediaRating()
    }, [dataShopee])


    const getDataExcel = (dataExcel) => {


        if (optionActive == "loox") {

            let file = dataExcel;
            var reader = new FileReader();
            reader.readAsText(file);
            reader.onload = function (event) {
                var csvdata = event.target.result;
                var rowDataTeste = csvdata.split('\n');
                let rowData = rowDataTeste.splice(1, rowDataTeste.length - 2);

                const dataReviews = rowData.map((item, index) => {

                    let dataArray = item.split(",")
                    if (dataArray.length == 15) {

                        return {
                            id: dataArray[0],
                            message: dataArray[6],
                            //rating: parseInt(dataArray[2]),
                            rating: parseInt(dataArray[2]),
                            status: "approved",
                            name: dataArray[5],
                            email: dataArray[3],
                            whatsapp: "",
                            images: dataArray[4].length > 0 ? [dataArray[4]] : [],
                            videos: [],
                            date: dataArray[7].split("T")[0]

                        }
                    } else {
                        let message = ''
                        let position = 6

                        for (let index = 15; index < dataArray.length; index++) {
                            message += dataArray[position];
                            if (index + 1 != dataArray.length) message += ", "
                            position++
                        }



                        let dataArrayCustom = []

                        dataArrayCustom[0] = dataArray[0]
                        dataArrayCustom[1] = dataArray[1]
                        dataArrayCustom[2] = dataArray[2]
                        dataArrayCustom[3] = dataArray[3]
                        dataArrayCustom[4] = dataArray[4]
                        dataArrayCustom[5] = dataArray[5]
                        dataArrayCustom[6] = message
                        dataArrayCustom[7] = dataArray[dataArray.length - 8]
                        dataArrayCustom[8] = dataArray[dataArray.length - 7]
                        dataArrayCustom[9] = dataArray[dataArray.length - 6]
                        dataArrayCustom[10] = dataArray[dataArray.length - 5]
                        dataArrayCustom[11] = dataArray[dataArray.length - 4]
                        dataArrayCustom[12] = dataArray[dataArray.length - 3]
                        dataArrayCustom[13] = dataArray[dataArray.length - 2]
                        dataArrayCustom[14] = dataArray[dataArray.length - 1]
                        return {
                            id: dataArrayCustom[0],
                            message: dataArrayCustom[6],
                            //rating: parseInt(dataArrayCustom[2]),
                            rating: parseInt(dataArrayCustom[2]),
                            status: "approved",
                            name: dataArrayCustom[5],
                            email: dataArrayCustom[3],
                            whatsapp: "",
                            images: dataArrayCustom[4].length > 0 ? [dataArrayCustom[4]] : [],
                            videos: [],
                            date: dataArrayCustom[7].split("T")[0]

                        }

                    }

                })


                setdataShopee(dataReviews)
                dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "list-reviews" } })

            }
        } else if (optionActive == "ryviu") {

            let file = dataExcel;
            var reader = new FileReader();
            reader.readAsText(file);
            reader.onload = function (event) {
                var csvdata = event.target.result;
                var rowDataTeste = csvdata.split('\n');
                let rowData = rowDataTeste.splice(1, rowDataTeste.length - 2);

                const dataReviews = rowData.map((item, index) => {

                    let dataArray = item.split(",")
                    if (dataArray.length == 12) {

                        return {
                            id: dataArray[0],
                            message: dataArray[5].replace(/[\\"]/g, ""),
                            rating: parseInt(dataArray[1]),
                            status: "approved",
                            name: dataArray[3].replace(/[\\"]/g, ""),
                            email: dataArray[4],
                            whatsapp: "",
                            images: dataArray[6].length > 0 ? [dataArray[6]] : [],
                            videos: [],
                            date: dataArray[7].split(" ")[0].replace(/[\\"]/g, "")

                        }
                    } else {

                        let message = ''
                        let position = 5

                        for (let index = 12; index < dataArray.length; index++) {
                            message += dataArray[position];
                            if (index + 1 != dataArray.length) message += ", "
                            position++
                        }



                        let dataArrayCustom = []
                        dataArrayCustom[0] = dataArray[0]
                        dataArrayCustom[1] = dataArray[1]
                        dataArrayCustom[2] = dataArray[2]
                        dataArrayCustom[3] = dataArray[3]
                        dataArrayCustom[4] = dataArray[4]
                        dataArrayCustom[5] = message
                        dataArrayCustom[6] = dataArray[dataArray.length - 6]
                        dataArrayCustom[7] = dataArray[dataArray.length - 5]
                        dataArrayCustom[8] = dataArray[dataArray.length - 4]
                        dataArrayCustom[9] = dataArray[dataArray.length - 3]
                        dataArrayCustom[10] = dataArray[dataArray.length - 2]
                        dataArrayCustom[11] = dataArray[dataArray.length - 1]
                        return {
                            id: dataArrayCustom[0],
                            message: dataArrayCustom[5].replace(/[\\"]/g, ""),
                            rating: parseInt(dataArrayCustom[1]),
                            status: "approved",
                            name: dataArrayCustom[3].replace(/[\\"]/g, ""),
                            email: dataArrayCustom[4],
                            whatsapp: "",
                            images: dataArrayCustom[6].length > 0 ? [dataArrayCustom[6]] : [],
                            videos: [],
                            date: dataArrayCustom[7].split(" ")[0].replace(/[\\"]/g, "")

                        }
                    }


                })

                setdataShopee(dataReviews)
                dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "list-reviews" } })
            }
        } else if (optionActive == "excel") {

            var reader = new FileReader();
            reader.readAsArrayBuffer(dataExcel);

            reader.onload = function (event) {
                var data = new Uint8Array(reader.result);
                var work_book = XLSX.read(data, { type: 'array' });
                var sheet_name = work_book.SheetNames;
                var sheet_data = XLSX.utils.sheet_to_json(work_book.Sheets[sheet_name[0]], { header: 1 });

                sheet_data.shift()

                const dataReviews = sheet_data.map((item) => {
                    const dataNova = new Date(typeof item[9] == "string" ? item[9]?.split('/')?.reverse()?.join('-') : "2022-10-12")
                    return {
                        id: item[0],
                        message: item[1],
                        rating: item[2],
                        status: item[3],
                        name: item[4],
                        email: item[5],
                        whatsapp: item[6],
                        images: typeof item[7] == "undefined" ? undefined : item[7].split(","),
                        videos: item[8],
                        date: dataNova.toISOString().slice(0, 10)//typeof item[9] == "string" ? item[9]?.split('/')?.reverse()?.join('-') : "2022-10-12"
                    }
                })

                setdataShopee(dataReviews)
                dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "list-reviews" } })
            }
        }

    }

    useEffect(() => {


        if (pageImportReviews > 1) {
            let aux = linkShopee?.split('-i.')[1]
            aux = aux?.split('?')[0]

            let shopId = aux?.split('.')[0];
            let itemId = aux?.split('.')[1];



            dispatch({
                type: "SAGA_IMPORT_SHOPEE_MORE_PRODUCTS", payload: {
                    shopId: shopId,
                    itemId: itemId,
                    limit: numberReviews,
                    midias: checkImages ? 1 : 0,
                    midiasOne: checkImagesOne ? 1 : 0,
                    url: linkShopee,
                    offset: (pageImportReviews - 1) * numberReviews
                }
            })
        }
    }, [pageImportReviews])

    useEffect(() => {

        if (statusImportShopeeMore?.loading == false && statusImportShopeeMore.error == false && statusImportShopeeMore.data?.length == 0) {

        } else {
            if (statusImportShopeeMore?.loading == false) {
                if (dataShopee[dataShopee?.length - 1]?.id != statusImportShopeeMore?.data[statusImportShopeeMore?.data?.length - 1]?.id) {
                    setdataShopee([...dataShopee, ...statusImportShopeeMore?.data])
                }
            }

        }
    }, [statusImportShopeeMore])

    const mediasTransform = (data) => {
        return data.map((item) => {
            return item.url
        })
    }

    const transformReview = (data) => {


        const dataTransform = data.map((item) => {
            return (
                {
                    id: item.id,
                    message: item.message,
                    rating: item.rating,
                    status: item.status,
                    name: item.name,
                    email: item.iemail,
                    whatsapp: item.whatsapp,
                    images: mediasTransform(item.medias),
                    videos: [],
                    date: item.created_at.split('T')[0]
                }
            )
        })

        return dataTransform

    }

    const goProductsImport = () => {
        if (productsImport.data.meta.next_page_url != null) {
            dispatch({
                type: "SAGA_LIST_PRODUCTS_IMPORT", payload: {
                    search: "",
                    perPage: 40,
                    page: productsImport.data.meta.current_page + 1
                }
            })
        } else {
            setPaginateRProductsIsEnd(true)
        }




    }


    return (
        <Modal
            isOpen={props.visible}
            style={customStyles}
            ariaHideApp={false}
        //onRequestClose={() => { props.setVisibleModal(false) }}
        >
            <Container>

                {screenActive.screen == "options" &&
                    <Screen>
                        {/* <Logo>
                            <img src={LogoLily} />
                        </Logo> */}

                        <Title>
                            {translation.MeusProdutos.modalImport.desejaImportar}
                        </Title>

                        <BoxOptions>

                            <ItemOption onClick={() => { setOptionActive("excel"); dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "excel-data" } }) }} active={optionActive == "excel" ? true : false}>
                                <img src={LogoExcel} />
                                <label>Excel</label>
                            </ItemOption>

                            <ItemOption onClick={() => { setOptionActive("loox"); dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "excel-data" } }) }} active={optionActive == "loox" ? true : false}>
                                <img src={LogoLoox} />
                                <label>Loox</label>
                            </ItemOption>

                            <ItemOption onClick={() => { setOptionActive("ryviu"); dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "excel-data" } }) }} active={optionActive == "ryviu" ? true : false}>
                                <img src={LogoRyviu} />
                                <label>Ryviu</label>
                            </ItemOption>

                            <ItemOption onClick={() => {
                                dispatch({
                                    type: "SAGA_LIST_PRODUCTS_IMPORT", payload: {
                                        search: "",
                                        perPage: 40,
                                        page: 1
                                    }
                                });
                                setOptionActive("lily-product"); dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "lily-product-data" } })
                            }} active={optionActive == "lily-product" ? true : false}>
                                <Tag size="40" color="#808080" />
                                <label>{translation.products.produto}</label>
                            </ItemOption>

                            <ItemOption onClick={() => {
                                //setOptionActive("shopee");
                                //dispatch({                                    type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "shopee-data" }                                })
                            }}
                                active={optionActive == "shopee" ? true : false} >
                                <img src={LogoShopee} />
                                <label>{translation.products.indisponivel}</label>
                            </ItemOption>

                        </BoxOptions>

                        <Close onClick={() => { closeModal() }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M1.66675 12.3334L12.3334 1.66669M1.66675 1.66669L12.3334 12.3334" stroke="#767676" stroke-width="1.77778" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </Close>
                    </Screen>}

                {screenActive.screen == "shopee-data" &&
                    <Screen>
                        {/* <Logo>
                            <img src={LogoLily} />
                        </Logo> */}
                        <LogoToPlatform>
                            <div>
                                <img src={LogoShopee} />
                            </div>

                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                    <g filter="url(#filter0_d_1866_671)">
                                        <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>

                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                    <g filter="url(#filter0_d_1866_671)">
                                        <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </span>
                            <div>
                                <img src={LogoLily} />
                            </div>

                        </LogoToPlatform>

                        <Title>
                            {translation.MeusProdutos.modalImport.preenchaCampos}
                        </Title>

                        <Form>
                            <InputCustom>
                                <input onChange={(e) => setLinkShopee(e.target.value)} value={linkShopee} type='text' placeholder={translation.MeusProdutos.modalImport.linkProduto} />
                                <svg onClick={() => { pasteUrl() }} xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path d="M13.8333 6.00002C13.8333 4.98336 13.4295 4.00833 12.7106 3.28944C11.9917 2.57055 11.0167 2.16669 10 2.16669H5.32468C5.19207 2.16669 5.06489 2.21937 4.97112 2.31313C4.87736 2.4069 4.82468 2.53408 4.82468 2.66669C4.82468 2.7993 4.87736 2.92647 4.97112 3.02024C5.06489 3.11401 5.19207 3.16669 5.32468 3.16669H10C10.7515 3.16669 11.4721 3.4652 12.0035 3.99655C12.5348 4.5279 12.8333 5.24857 12.8333 6.00002V10.738C12.8333 10.8706 12.886 10.9978 12.9798 11.0916C13.0736 11.1853 13.2007 11.238 13.3333 11.238C13.466 11.238 13.5931 11.1853 13.6869 11.0916C13.7807 10.9978 13.8333 10.8706 13.8333 10.738V6.00002Z" fill="#667085" fill-opacity="0.5" />
                                    <path d="M3.73133 4.52868C5.90375 4.28715 8.09625 4.28715 10.2687 4.52868C10.5692 4.56219 10.8497 4.69634 11.0644 4.90935C11.2791 5.12236 11.4154 5.40171 11.4513 5.70201C11.7076 7.89299 11.7076 10.1064 11.4513 12.2973C11.4156 12.5977 11.2792 12.8771 11.0645 13.0901C10.8498 13.3032 10.5693 13.4373 10.2687 13.4707C8.108 13.7127 5.892 13.7127 3.73133 13.4707C3.43071 13.4373 3.15023 13.3032 2.9355 13.0901C2.72077 12.8771 2.58444 12.5977 2.54867 12.2973C2.2924 10.1064 2.2924 7.89299 2.54867 5.70201C2.58455 5.40171 2.72093 5.12236 2.93563 4.90935C3.15034 4.69634 3.43075 4.56219 3.73133 4.52868Z" fill="#667085" fill-opacity="0.5" />
                                </svg>
                            </InputCustom>
                            <BoxCheck>
                                <ItemCheck active={checkImages} onClick={() => { setCheckImages(!checkImages) }}>
                                    <span>
                                        {checkImages && <BsCheck size={12} color={"#19b674"} />}
                                    </span>
                                    <label> {translation.MeusProdutos.modalImport.somenteImagem}</label>
                                </ItemCheck>

                                <ItemCheck active={checkImagesOne} onClick={() => { setCheckImagesOne(!checkImagesOne) }}>
                                    <span>
                                        {checkImagesOne && <BsCheck size={12} color={"#19b674"} />}
                                    </span>
                                    <label> {translation.MeusProdutos.modalImport.umaImagem}</label>
                                </ItemCheck>
                            </BoxCheck>
                            <BoxQtd>
                                <label> {translation.MeusProdutos.modalImport.qtdReviews}</label>
                                <span>
                                    <input onChange={(e) => { setnumberReviews(e.target.value) }} id="myinput" type='range' min="0" max="50" value={numberReviews} />
                                    <label>{numberReviews}</label>
                                </span>
                            </BoxQtd>
                        </Form>

                        <BoxActions>
                            <ItemBoxAction onClick={() => { dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "options" } }) }} fontColor={"#344054"} backgroundColor={"transparent"} borderColor={"#D0D5DD"} >
                                {translation.MeusProdutos.modalImport.voltar}
                            </ItemBoxAction>

                            <ItemBoxAction onClick={() => { linkShopee != "" && importReviews(); linkShopee != "" && dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "shopee-loading" } }) }} fontColor={"#ffffff"} backgroundColor={"#19b674"} borderColor={"#19b674"}>
                                Importar
                            </ItemBoxAction>
                        </BoxActions>
                    </Screen>}

                {screenActive.screen == "shopee-loading" &&
                    <Screen>
                        <LogoToPlatform>
                            <div>
                                {optionActive == "shopee" ?
                                    <img src={LogoShopee} /> :
                                    <Tag size="40" color="#808080" />
                                }

                            </div>

                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                    <g filter="url(#filter0_d_1866_671)">
                                        <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>

                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                    <g filter="url(#filter0_d_1866_671)">
                                        <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </span>
                            <div>
                                {optionActive == "shopee" ?
                                    <img src={LogoLily} /> :
                                    <Tag size="40" color="#808080" />
                                }

                            </div>

                        </LogoToPlatform>

                        {/* <Title>
                            {loadingShopeeText}
                        </Title> */}

                        <BoxLoading>
                            <svg xmlns="http://www.w3.org/2000/svg" width="196" height="196" viewBox="0 0 196 196" fill="none">
                                <path d="M196 98C196 152.124 152.124 196 98 196C43.8761 196 0 152.124 0 98C0 43.8761 43.8761 0 98 0C152.124 0 196 43.8761 196 98ZM11.6006 98C11.6006 145.717 50.2829 184.399 98 184.399C145.717 184.399 184.399 145.717 184.399 98C184.399 50.2829 145.717 11.6006 98 11.6006C50.2829 11.6006 11.6006 50.2829 11.6006 98Z" fill="url(#paint0_linear_1828_636)" fill-opacity="0.2" />
                                <defs>
                                    <linearGradient id="paint0_linear_1828_636" x1="98" y1="0" x2="-4.5" y2="166.5" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FFC400" />
                                        <stop offset="1" stop-color="#FFC400" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>

                            <label>
                                {loadingShopeeText}
                            </label>
                        </BoxLoading>

                        <BoxActions>
                            <ItemBoxAction onClick={() => { closeModal() }} fontColor={"#ffffff"} backgroundColor={"#19b674"} borderColor={"#19b674"}>
                                Cancelar
                            </ItemBoxAction>
                        </BoxActions>
                    </Screen>}

                {screenActive.screen == "list-reviews" &&
                    <Screen style={{ position: "relative" }}>

                        <ConfirmAlert confirm={deleteAllImageReviewShopee} setVisible={setVisibleConfirm} visible={visibleConfirm} title={translation.MeusProdutos.modalImport.desejaExcluirImagens} text={translation.MeusProdutos.modalImport.naoReverter} />

                        <ConfirmAlertDeleteReview id={reviewIdSelected} confirm={deleteReviewShopee} setVisible={setVisibleConfirmDeleteReview} visible={visibleConfirmDeleteReview} title={translation.MeusProdutos.modalImport.desejaExcluirAvaliacao} text={translation.MeusProdutos.modalImport.naoReverter} />

                        <BoxTitleReviews>
                            <BoxTitleReviewsLeft >
                                <label> {translation.MeusProdutos.modalImport.mediaEstrelas}</label>
                                <div>
                                    <span>
                                        {calcMediaRating().toFixed(1) >= 0.6 ?
                                            <img style={{ cursor: "pointer" }} width={22} height={22} src={StarCheia} /> :
                                            calcMediaRating().toFixed(1) >= 0.1 ?
                                                <img style={{ cursor: "pointer" }} width={22} height={22} src={StarMeia} /> :
                                                <img style={{ cursor: "pointer" }} width={22} height={22} src={StarVazia} />}

                                        {calcMediaRating().toFixed(1) >= 1.6 ?
                                            <img style={{ cursor: "pointer" }} width={22} height={22} src={StarCheia} /> :
                                            calcMediaRating().toFixed(1) >= 1.1 ?
                                                <img style={{ cursor: "pointer" }} width={22} height={22} src={StarMeia} /> :
                                                <img style={{ cursor: "pointer" }} width={22} height={22} src={StarVazia} />}

                                        {calcMediaRating().toFixed(1) >= 2.6 ?
                                            <img style={{ cursor: "pointer" }} width={22} height={22} src={StarCheia} /> :
                                            calcMediaRating().toFixed(1) >= 2.1 ?
                                                <img style={{ cursor: "pointer" }} width={22} height={22} src={StarMeia} /> :
                                                <img style={{ cursor: "pointer" }} width={22} height={22} src={StarVazia} />}

                                        {calcMediaRating().toFixed(1) >= 3.6 ?
                                            <img style={{ cursor: "pointer" }} width={22} height={22} src={StarCheia} /> :
                                            calcMediaRating().toFixed(1) >= 3.1 ?
                                                <img style={{ cursor: "pointer" }} width={22} height={22} src={StarMeia} /> :
                                                <img style={{ cursor: "pointer" }} width={22} height={22} src={StarVazia} />}

                                        {calcMediaRating().toFixed(1) >= 4.6 ?
                                            <img style={{ cursor: "pointer" }} width={22} height={22} src={StarCheia} /> :
                                            calcMediaRating().toFixed(1) >= 4.1 ?
                                                <img style={{ cursor: "pointer" }} width={22} height={22} src={StarMeia} /> :
                                                <img style={{ cursor: "pointer" }} width={22} height={22} src={StarVazia} />}

                                    </span>
                                    <label>{calcMediaRating().toFixed(1)}</label>
                                </div>
                            </BoxTitleReviewsLeft>

                            <BoxTitleReviewsAction onClick={() => { setVisibleConfirm(true) }}>
                                <label> {translation.MeusProdutos.modalImport.apagarImagens}</label>
                            </BoxTitleReviewsAction>

                        </BoxTitleReviews>

                        <BoxReviews>
                            {dataShopee.map((item) => {
                                return (
                                    <CardImportReview
                                        changeDate={changeDateReviewShopee}
                                        changeName={changeNameReviewShopee}
                                        changeMessage={changeMessageReviewShopee}
                                        changeRating={changeStarReviewShopee}
                                        deleteImage={deleteImageReviewShopee}
                                        delete={deleteReviewShopee}
                                        item={item}
                                        setReviewSelected={setReviewIdSelected}
                                        setVisibleConfirm={setVisibleConfirmDeleteReview}
                                        countAlertDelete={countAlertDelete}
                                        setCountAlertDelete={setCountAlertDelete}
                                    />
                                )
                            })}

                            {
                                statusControlImportShopeeMore.isEnd || paginateReviewsIsEnd ?
                                    <ButtonMoreReviews >
                                        <label style={{ fontSize: "12px" }}>
                                        {translation.products.fim}
                                        </label>
                                    </ButtonMoreReviews> :
                                    <ButtonMoreReviews onClick={() => {
                                        optionActive == "shopee" ?
                                            setpageImportReviews(pageImportReviews + 1) :
                                            optionActive == "lily-product" ? goSearchReviewsImportProduct(0) :
                                                console.log("teste")
                                    }}>
                                        <label style={{ fontSize: "12px" }}>
                                            {statusImportShopeeMore.loading ?
                                                "Buscando..." :
                                                `Buscar + ${numberReviews} ${translation.products.avaliacoes}`}
                                        </label>
                                    </ButtonMoreReviews>
                            }


                            {/* {Array(10).fill(<CardImportReview />)} */}
                        </BoxReviews>

                        <BoxActions>
                            <ItemBoxAction
                                onClick={() => {
                                    optionActive == "shopee" ?
                                        dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "shopee-data" } }) :
                                        optionActive == "lily-product" ? dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "lily-product-data" } }) :
                                            dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "options" } })


                                    dispatch({ type: "PRODUCTS_CONTROL_IMPORT_SHOPEE_MORE", payload: { isEnd: false } });
                                    setpageImportReviews(1)
                                    setdataShopee([])
                                    setPaginateReviewsIsEnd(false)
                                }}
                                fontColor={"#344054"} backgroundColor={"transparent"} borderColor={"#D0D5DD"} >
                                {translation.MeusProdutos.modalImport.voltar}
                            </ItemBoxAction>

                            <ItemBoxAction
                                onClick={() => {
                                    saveReviews();
                                    dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "shopee-save" } });
                                    dispatch({ type: "PRODUCTS_CONTROL_IMPORT_SHOPEE_MORE", payload: { isEnd: false } });
                                }}
                                fontColor={"#ffffff"} backgroundColor={"#19b674"} borderColor={"#19b674"}>
                                Enviar
                            </ItemBoxAction>
                        </BoxActions>
                    </Screen>}

                {screenActive.screen == "shopee-save" &&
                    <Screen style={{ overflow: "hidden" }} onClick={() => { dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "shopee-end" } }) }} >
                        <LogoToPlatform>
                            <div>
                                <img src={LogoShopee} />
                            </div>

                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                    <g filter="url(#filter0_d_1866_671)">
                                        <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>

                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                    <g filter="url(#filter0_d_1866_671)">
                                        <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </span>
                            <div>
                                <img src={LogoLily} />
                            </div>

                        </LogoToPlatform>

                        <Title>
                            {translation.MeusProdutos.modalImport.enviandoPagina}
                        </Title>

                        <BoxLoading>
                            <svg xmlns="http://www.w3.org/2000/svg" width="196" height="196" viewBox="0 0 196 196" fill="none">
                                <path d="M196 98C196 152.124 152.124 196 98 196C43.8761 196 0 152.124 0 98C0 43.8761 43.8761 0 98 0C152.124 0 196 43.8761 196 98ZM11.6006 98C11.6006 145.717 50.2829 184.399 98 184.399C145.717 184.399 184.399 145.717 184.399 98C184.399 50.2829 145.717 11.6006 98 11.6006C50.2829 11.6006 11.6006 50.2829 11.6006 98Z" fill="url(#paint0_linear_1828_636)" fill-opacity="0.2" />
                                <defs>
                                    <linearGradient id="paint0_linear_1828_636" x1="98" y1="0" x2="-4.5" y2="166.5" gradientUnits="userSpaceOnUse">
                                        <stop stop-color="#FFC400" />
                                        <stop offset="1" stop-color="#FFC400" stop-opacity="0" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            {
                                statusControlSaveImportShopee.qtdTotal != 0 &&
                                <label>{statusControlSaveImportShopee.qtdSuccess} de {statusControlSaveImportShopee.qtdTotal}</label>
                            }
                        </BoxLoading>
                    </Screen>}

                {screenActive.screen == "shopee-end" &&
                    <Screen>
                        <Logo>
                            <img src={LogoLily} />
                            <IconSuccess>
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 15 15" fill="none">
                                    <path d="M13.3828 6.51515V7.09375C13.382 8.44994 12.9429 9.76954 12.1309 10.8558C11.3188 11.942 10.1775 12.7366 8.87692 13.1211C7.57639 13.5057 6.1864 13.4595 4.91426 12.9895C3.64211 12.5195 2.55597 11.6509 1.81783 10.5132C1.07969 9.37544 0.729087 8.0296 0.81832 6.67635C0.907553 5.3231 1.43184 4.03495 2.31298 3.00402C3.19412 1.97308 4.38492 1.25459 5.70776 0.955715C7.0306 0.656836 8.41462 0.793578 9.6534 1.34555M13.3828 2.0625L7.09375 8.35785L5.20703 6.47113" stroke="#039855" stroke-width="1.25781" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </IconSuccess>
                        </Logo>

                        <Title>
                            {translation.MeusProdutos.modalImport.reviewsImportadas}
                        </Title>

                        <TextEnd>
                            {translation.MeusProdutos.modalImport.confiraReviews}
                        </TextEnd>

                        <Close onClick={() => { closeModal() }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
                                <path d="M1.66675 12.3334L12.3334 1.66669M1.66675 1.66669L12.3334 12.3334" stroke="#767676" stroke-width="1.77778" stroke-linecap="round" stroke-linejoin="round" />
                            </svg>
                        </Close>
                    </Screen>}

                {screenActive.screen == "excel-data" &&
                    <Screen>
                        <LogoToPlatform>
                            {optionActive == "excel" ?
                                <div>
                                    <img src={LogoExcel} />
                                </div> :
                                optionActive == "loox" ?
                                    <div>
                                        <img src={LogoLoox} />
                                    </div> : optionActive == "ryviu" ?
                                        <div>
                                            <img src={LogoRyviu} />
                                        </div> : null}


                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                    <g filter="url(#filter0_d_1866_671)">
                                        <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>

                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                    <g filter="url(#filter0_d_1866_671)">
                                        <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </span>
                            <div>
                                <img src={LogoLily} />
                            </div>

                        </LogoToPlatform>
                        <Title>
                            {translation.MeusProdutos.modalImport.importarReviewsLily}
                        </Title>

                        <Form>
                            <label style={{ fontSize: "14px", justifyContent: "center", textAlign: "center" }} >
                                {translation.MeusProdutos.modalImport.lugarCerto}
                                {optionActive == "excel" && <TextDownload style={{ cursor: "pointer" }} href={LilyDownloadModelo} download>{translation.MeusProdutos.modalImport.baixarPlanilha}</TextDownload>}
                            </label>
                        </Form>

                        <BoxActions>
                            <ItemBoxAction onClick={() => { dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "options" } }) }} fontColor={"#344054"} backgroundColor={"transparent"} borderColor={"#D0D5DD"} >
                                {translation.MeusProdutos.modalImport.voltar}
                            </ItemBoxAction>


                            <ItemBoxActionLabel for={"file-excel"} fontColor={"#ffffff"} backgroundColor={"#19b674"} borderColor={"#19b674"}>
                                Importar Reviews
                            </ItemBoxActionLabel>
                            <input onChange={(e) => { getDataExcel(e.target.files[0]) }} id='file-excel' type='file' style={{ display: "none" }} accept={optionActive == "excel" ? '.xls, .xlsx' : ".csv"} />


                        </BoxActions>
                    </Screen>}

                {screenActive.screen == "lily-product-data" &&
                    <Screen>
                        <LogoToPlatform>
                            <div>
                                <Tag size="40" color="#808080" />
                            </div>


                            <span>
                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                    <g filter="url(#filter0_d_1866_671)">
                                        <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>

                                <svg xmlns="http://www.w3.org/2000/svg" width="32" height="16" viewBox="0 0 32 16" fill="none">
                                    <g filter="url(#filter0_d_1866_671)">
                                        <path d="M6.5 10L16.2866 9.99977L25.5 9.99969L18.7941 6" stroke="#FFC400" stroke-width="4" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges" />
                                    </g>
                                    <defs>
                                        <filter id="filter0_d_1866_671" x="0.5" y="-0.000366211" width="31.0002" height="16.0004" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                            <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                            <feOffset />
                                            <feGaussianBlur stdDeviation="2" />
                                            <feComposite in2="hardAlpha" operator="out" />
                                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1866_671" />
                                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1866_671" result="shape" />
                                        </filter>
                                    </defs>
                                </svg>
                            </span>
                            <div>
                                <Tag size="40" color="#808080" />
                            </div>

                        </LogoToPlatform>
                        <Title>
                            {translation.products.importarOutroProduto} teste
                        </Title>

                        <Form>
                            <ListProducts>
                                {dataProductsImport.map((item) => {
                                    return (
                                        <CardImportProduct
                                            key={item.id}
                                            goSearchReviewsImportProduct={goSearchReviewsImportProduct}
                                            setProductImportSelected={setProductImportSelected}
                                            //checkedAll={checkedAll}
                                            item={item} />
                                    )
                                })}

                            </ListProducts>
                            {paginateProductsIsEnd ?
                                <ButtonMoreReviews >
                                    <label style={{ fontSize: "12px" }}>
                                        Fim
                                    </label>
                                </ButtonMoreReviews> :
                                <ButtonMoreReviews onClick={() => {
                                    goProductsImport()
                                }}>
                                    <label style={{ fontSize: "12px" }}>
                                        {productsImport.loading ?
                                            "Buscando..." :
                                            `Buscar + 40`}
                                    </label>
                                </ButtonMoreReviews>
                            }
                        </Form>

                        <BoxActions>
                            <ItemBoxAction onClick={() => { dispatch({ type: "PRODUCTS_CONTROL_SCREEN_ACTIVE", payload: { screen: "options" } }) }} fontColor={"#344054"} backgroundColor={"transparent"} borderColor={"#D0D5DD"} >
                                {translation.MeusProdutos.modalImport.voltar}
                            </ItemBoxAction>

                        </BoxActions>

                    </Screen>}

            </Container>
        </Modal>
    )
}

export default ModalImportShopeeNew



const ConfirmAlert = (props) => {
    return (
        <BoxConfirm visible={props?.visible}>
            <ContainerConfirm>
                <BoxIconConfirm>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M11.9998 7.99996V12M11.9998 16H12.0098M10.2898 2.85996L1.81978 17C1.64514 17.3024 1.55274 17.6453 1.55177 17.9945C1.55079 18.3437 1.64127 18.6871 1.8142 18.9905C1.98714 19.2939 2.2365 19.5467 2.53748 19.7238C2.83847 19.9009 3.18058 19.9961 3.52978 20H20.4698C20.819 19.9961 21.1611 19.9009 21.4621 19.7238C21.7631 19.5467 22.0124 19.2939 22.1854 18.9905C22.3583 18.6871 22.4488 18.3437 22.4478 17.9945C22.4468 17.6453 22.3544 17.3024 22.1798 17L13.7098 2.85996C13.5315 2.56607 13.2805 2.32308 12.981 2.15444C12.6814 1.98581 12.3435 1.89722 11.9998 1.89722C11.656 1.89722 11.3181 1.98581 11.0186 2.15444C10.7191 2.32308 10.468 2.56607 10.2898 2.85996Z" stroke="#DC6803" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </BoxIconConfirm>
                <BoxTextConfirm>
                    <p>{props.title} </p>
                    <label>{props.text}</label>
                </BoxTextConfirm>
                <BoxActions>
                    <ItemBoxAction onClick={() => { props.setVisible(false) }} fontColor={"#344054"} backgroundColor={"transparent"} borderColor={"#D0D5DD"} >
                        Cancelar
                    </ItemBoxAction>

                    <ItemBoxAction onClick={() => { props.confirm(); props.setVisible(false) }} fontColor={"#ffffff"} backgroundColor={"#19b674"} borderColor={"#19b674"}>
                        Confirmar
                    </ItemBoxAction>
                </BoxActions>
            </ContainerConfirm>
        </BoxConfirm>
    )
}

const ConfirmAlertDeleteReview = (props) => {
    return (
        <BoxConfirm visible={props?.visible}>
            <ContainerConfirm>
                <BoxIconConfirm>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M11.9998 7.99996V12M11.9998 16H12.0098M10.2898 2.85996L1.81978 17C1.64514 17.3024 1.55274 17.6453 1.55177 17.9945C1.55079 18.3437 1.64127 18.6871 1.8142 18.9905C1.98714 19.2939 2.2365 19.5467 2.53748 19.7238C2.83847 19.9009 3.18058 19.9961 3.52978 20H20.4698C20.819 19.9961 21.1611 19.9009 21.4621 19.7238C21.7631 19.5467 22.0124 19.2939 22.1854 18.9905C22.3583 18.6871 22.4488 18.3437 22.4478 17.9945C22.4468 17.6453 22.3544 17.3024 22.1798 17L13.7098 2.85996C13.5315 2.56607 13.2805 2.32308 12.981 2.15444C12.6814 1.98581 12.3435 1.89722 11.9998 1.89722C11.656 1.89722 11.3181 1.98581 11.0186 2.15444C10.7191 2.32308 10.468 2.56607 10.2898 2.85996Z" stroke="#DC6803" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </BoxIconConfirm>
                <BoxTextConfirm>
                    <p>{props.title} </p>
                    <label>{props.text}</label>
                </BoxTextConfirm>
                <BoxActions>
                    <ItemBoxAction onClick={() => { props.setVisible(false) }} fontColor={"#344054"} backgroundColor={"transparent"} borderColor={"#D0D5DD"} >
                        Cancelar
                    </ItemBoxAction>

                    <ItemBoxAction onClick={() => { props.confirm(props.id); props.setVisible(false) }} fontColor={"#ffffff"} backgroundColor={"#19b674"} borderColor={"#19b674"}>
                        Confirmar
                    </ItemBoxAction>
                </BoxActions>
            </ContainerConfirm>
        </BoxConfirm>
    )
}


import styled, { keyframes } from "styled-components";

const progress = keyframes`
  0% { 
    background-position: -100%; 
  }
  100% { 
    background-position: 200%; 
  }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 20px;
    //background-color: #f2f2f2;
    height: calc(100vh - 140px);
    overflow: hidden;
`

export const Line = styled.div`
    display: flex;
    justify-content: space-between;
    
`

export const Contents = styled.div`
    display: flex;
    min-height: 900px;
    width: 100%;
    background-color: #f2f2f2;
    border-radius: 8px;
    position: relative;
     &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, transparent 0%, #FBF9F6 50%, transparent 100%); /* Linha de luz */
        background-size: 200% 100%;
        animation: ${progress} 2s infinite;
        border-radius: 8px;
    }
`

export const Animation = styled.div`
    display: flex;
    min-height: 300px;
    width: 65%;
    background-color: #f2f2f2;
    border-radius: 8px;
position: relative;
     &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, transparent 0%, #FBF9F6 50%, transparent 100%); /* Linha de luz */
                background-size: 200% 100%;
                animation: ${progress} 2s infinite;
                border-radius: 8px;
            }
`
export const Search = styled.div`
    display: flex;
    min-height: 300px;
    width: 30%;
    background-color: #f2f2f2;
    border-radius: 8px;
position: relative;
     &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, transparent 0%, #FBF9F6 50%, transparent 100%); /* Linha de luz */
                background-size: 200% 100%;
                animation: ${progress} 2s infinite;
                border-radius: 8px;
            }
`
export const Total = styled.div`
    display: flex;
    min-height: 150px;
    width: 65%;
    background-color: #f2f2f2;
    border-radius: 8px;
position: relative;
     &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, transparent 0%, #FBF9F6 50%, transparent 100%); /* Linha de luz */
                background-size: 200% 100%;
                animation: ${progress} 2s infinite;
                border-radius: 8px;
            }
`
export const Size = styled.div`
    display: flex;
    min-height: 150px;
    width: 30%;
    background-color: #f2f2f2;
    border-radius: 8px;
position: relative;
     &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, transparent 0%, #FBF9F6 50%, transparent 100%); /* Linha de luz */
                background-size: 200% 100%;
                animation: ${progress} 2s infinite;
                border-radius: 8px;
            }
`
export const Top = styled.div`
    display: flex;
    min-height: 400px;
    width: 30%;
    background-color: #f2f2f2;
    border-radius: 8px;
position: relative;
     &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, transparent 0%, #FBF9F6 50%, transparent 100%); /* Linha de luz */
                background-size: 200% 100%;
                animation: ${progress} 2s infinite;
                border-radius: 8px;
            }
`
export const Time = styled.div`
    display: flex;
    min-height: 400px;
    width: 65%;
    background-color: #f2f2f2;
    border-radius: 8px;
position: relative;
     &::before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to right, transparent 0%, #FBF9F6 50%, transparent 100%); /* Linha de luz */
                background-size: 200% 100%;
                animation: ${progress} 2s infinite;
                border-radius: 8px;
            }
`


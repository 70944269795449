import React from 'react'
import { Container, Table, TableContents, TableLine, TableLineItem, TableTitle, TableTitleItem, Title } from './styled'


const LazyLoading = () => {
    return (
        <Container>
            <Title>
                <div />
                <span />
            </Title>
            <Table>
                <TableTitle>
                    <TableTitleItem />
                    <TableTitleItem />
                    <TableTitleItem />
                    <TableTitleItem />
                    <TableTitleItem />
                </TableTitle>
                <TableContents>
                    {Array(20).fill(
                        <TableLine>
                            <TableLineItem spaceLeft={0} spaceRight={60} space={40} />
                            <TableLineItem spaceLeft={0} spaceRight={0} space={100} />
                            <TableLineItem spaceLeft={15} spaceRight={15} space={70} />
                            <TableLineItem spaceLeft={10} spaceRight={10} space={80} />
                            <TableLineItem spaceLeft={80} spaceRight={0} space={20} />
                            <TableLineItem spaceLeft={80} spaceRight={0} space={20} />
                        </TableLine>
                    )}
                </TableContents>

            </Table>
        </Container>
    )
}

export default LazyLoading
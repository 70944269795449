import React from 'react'
import { Banner, BarConfig, BarOptions, BoxPlan, Container, Details, Plan, Preview, } from './styled'


const LazyLoadingPlan = () => {
  return (
    <Container>
      <Banner />
      <BoxPlan>
        {Array(10).fill(<Plan />)}
      </BoxPlan>
      <Details />
    </Container>
  )
}

export default LazyLoadingPlan
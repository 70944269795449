import styled, { keyframes } from "styled-components";

const progress = keyframes`
  0% { 
    background-position: -100%; 
  }
  100% { 
    background-position: 200%; 
  }
`;

export const Container = styled.div`
    display: flex;
    gap: 20px;
    //background-color: #f2f2f2;
    height: calc(100vh - 140px);
    overflow: hidden;
    flex-direction: column;
`

export const CardIntegration = styled.div`
    display: flex;
    background-color: #f2f2f2;
    width: 100%;
    min-height: 120px;
    justify-content: space-between;
    gap: 20px;

    border-radius: 8px;
    position: relative;
    align-items: center;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(to right, transparent 0%, #FBF9F6 50%, transparent 100%); /* Linha de luz */
        background-size: 200% 100%;
        animation: ${progress} 2s infinite;
        border-radius: 8px;
    }
`

export const CardImg = styled.div`
    display: flex;
    background-color: #f2f2f2;  
    width: 50px;
    height: 50px;
`

export const CardText = styled.div`
    display: flex;
    background-color: #f2f2f2;
    flex-direction: column;
    flex: 1;
`

export const CardAction= styled.div`
    display: flex;
    background-color: #f2f2f2;
`
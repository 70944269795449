import { Container, BoxPreview, GridContainer, Card, ImageGrid, Image, ReviewsContainer, ReviewCard, ReviewerName, Rating, ReviewText, BoxCards, Title, Box, } from './styled'
import { useSelector } from 'react-redux';
import imgBackground from "../../../../assets/imgs/preview-background.png";
import BoxConfig from '../box-config-tema';
import { getTranslation } from '../../../../translations';
import CardMural01 from '../card-mural-01';
import { useState } from 'react';

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation, Scrollbar, A11y, EffectCards, EffectFade, EffectCube, EffectCoverflow, EffectFlip, EffectCreative } from "swiper/modules";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "./styles.css";
import { useRef } from 'react';
import { BodyNew, ContainerNew, TitleNew } from '../preview-home-carrossel-multi/styled';
import { useEffect } from 'react';
import CardMural02 from '../card-mural-02';
import CardMural03 from '../card-mural-03';

const PreviewMural = () => {

  const language = JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language
  const swiperRef = useRef(null);
  const configControl = useSelector(state => state.reducerControlConfigMural)
  const translation = getTranslation(JSON.parse(localStorage.getItem("depoimentos@login"))?.user?.language);


 


  const msg = [
    "Equipe muito prestativa, entrega rápida e produto de alta qualidade. Recomendo esta loja!",
    "Atendimento excelente, processo de compra fácil e entrega dentro do prazo. Muito satisfeito!",
    "Excelente experiência de compra! Encontrei tudo o que precisava nesta loja e o processo de compra foi rápido e fácil. A equipe foi muito prestativa e a entrega foi rápida. Estou muito satisfeito com minha compra e recomendo esta loja a todos os meus amigos.",
    "Atendimento ao cliente impecável, site fácil de navegar e entrega rápida. Voltarei a comprar!",
    "Rápido e eficiente, tudo perfeito!",
    "Produto de excelente qualidade, atendimento cortês e entrega rápida. Estou muito satisfeito!",
    "Processo de compra tranquilo, produto conforme descrito e entrega dentro do prazo. Recomendo!",
    "Serviço de alta qualidade, atendimento cordial e entrega rápida. Comprarei novamente!",
    "Entrega rápida, produto de qualidade e atendimento eficiente. Estou muito feliz com minha compra!",
  ]

  const msgEs = [
    "Equipo muy servicial, entrega rápida y producto de alta calidad. ¡Recomiendo esta tienda!",
    "Rápido y eficiente, todo perfecto!",
    "Proceso de compra tranquilo, producto conforme descrito y entrega dentro del plazo. ¡Recomiendo!",
    "Atención excelente, proceso de compra fácil y entrega dentro del plazo. ¡Muy satisfecho!",
    "Atención al cliente impecable, sitio fácil de navegar y entrega rápida. ¡Volveré a comprar!",
    "Servicio de alta calidad, atención cordial y entrega rápida. ¡Compraré de nuevo!",
    "¡Excelente experiencia de compra! Encontré todo lo que necesitaba en esta tienda y el proceso de compra fue rápido y fácil. El equipo fue muy servicial y la entrega fue rápida. Estoy muy satisfecho con mi compra y recomiendo esta tienda a todos mis amigos.",
    "Producto de excelente calidad, atención cortés y entrega rápida. ¡Estoy muy satisfecho!",
    "Entrega rápida, producto de calidad y atención eficiente. ¡Estoy muy feliz con mi compra!"
  ]

  useEffect(() => {
    swiperRef?.current?.swiper?.autoplay?.start()
    swiperRef?.current?.swiper?.navigation?.disable()
    swiperRef?.current?.swiper?.pagination?.disable()
  }, [configControl])

  useEffect(() => {
    var elements3 = document.getElementsByClassName('swiper-button-next'); // get all elements
    for (var i = 0; i < elements3.length; i++) {
      elements3[i].style.color = "transparent";
    }

    var elements4 = document.getElementsByClassName('swiper-button-prev'); // get all elements
    for (var i = 0; i < elements4.length; i++) {
      elements4[i].style.color = "transparent";
    }

  }, [configControl])

  return (
    configControl.mural_model_widget == "modelo01" ?
      <Container img={imgBackground} font={configControl.font}>
        <Box backgroundColor={configControl.mural_background_color_widget}>
          <Title
            color={configControl.mural_font_color_widget}
            font={configControl.mural_title_font_widget}
            size={configControl.mural_title_size_widget}
            position={configControl.mural_title_position_widget}
          >
            {configControl.mural_title_text_widget}
          </Title>
          <BoxCards>
            {configControl.mural_model_card == "modelo01" &&
              <>
                <CardMural01 item={{ name: "João Silva", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[0] : msg[0] }} />
                <CardMural01 item={{ name: "Maria Santos", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[1] : msg[1] }} />
                <CardMural01 item={{ name: "Ana Oliveira", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[2] : msg[2] }} />
                <CardMural01 item={{ name: "Pedro Pereira", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[3] : msg[3] }} />
                <CardMural01 item={{ name: "Juliana Costa", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[4] : msg[4] }} />
                <CardMural01 item={{ name: "Rafael Lima", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[5] : msg[5] }} />
                <CardMural01 item={{ name: "Camila Almeida", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[6] : msg[6] }} />
                <CardMural01 item={{ name: "Fernanda Souza", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[7] : msg[7] }} />
                <CardMural01 item={{ name: "Lucas Martins", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[8] : msg[8] }} />
              </>}

            {configControl.mural_model_card == "modelo02" &&
              <>
                <CardMural02 item={{ name: "João Silva", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[0] : msg[0] }} />
                <CardMural02 item={{ name: "Maria Santos", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[1] : msg[1] }} />
                <CardMural02 item={{ name: "Ana Oliveira", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[2] : msg[2] }} />
                <CardMural02 item={{ name: "Pedro Pereira", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[3] : msg[3] }} />
                <CardMural02 item={{ name: "Juliana Costa", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[4] : msg[4] }} />
                <CardMural02 item={{ name: "Rafael Lima", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[5] : msg[5] }} />
                <CardMural02 item={{ name: "Camila Almeida", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[6] : msg[6] }} />
                <CardMural02 item={{ name: "Fernanda Souza", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[7] : msg[7] }} />
                <CardMural02 item={{ name: "Lucas Martins", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[8] : msg[8] }} />
              </>}

              {configControl.mural_model_card == "modelo03" &&
              <>
                <CardMural03 item={{ name: "João Silva", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[0] : msg[0] }} />
                <CardMural03 item={{ name: "Maria Santos", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[1] : msg[1] }} />
                <CardMural03 item={{ name: "Ana Oliveira", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[2] : msg[2] }} />
                <CardMural03 item={{ name: "Pedro Pereira", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[3] : msg[3] }} />
                <CardMural03 item={{ name: "Juliana Costa", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[4] : msg[4] }} />
                <CardMural03 item={{ name: "Rafael Lima", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[5] : msg[5] }} />
                <CardMural03 item={{ name: "Camila Almeida", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[6] : msg[6] }} />
                <CardMural03 item={{ name: "Fernanda Souza", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[7] : msg[7] }} />
                <CardMural03 item={{ name: "Lucas Martins", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[8] : msg[8] }} />
              </>}
          </BoxCards>
        </Box>
      </Container> :
      configControl.mural_model_widget == "modelo02" ?
        <ContainerNew img={imgBackground}>
          <div style={{ padding: "0px 20px", backgroundColor: configControl.mural_background_color_widget }}>
            <Title style={{ paddingTop: "20px" }}
              color={configControl.mural_font_color_widget}
              font={configControl.mural_title_font_widget}
              size={configControl.mural_title_size_widget}
              position={configControl.mural_title_position_widget}
            >
              {configControl?.mural_title_text_widget == null || configControl?.mural_title_text_widget == "null" ? "" : configControl?.mural_title_text_widget}
            </Title>
            <BodyNew backgroundColor={configControl.mural_background_color_widget}>
              <Swiper
                ref={swiperRef}
                id='swiper-multi-mural'
                modules={[Navigation, Pagination, Scrollbar, Autoplay, A11y, EffectCards, EffectCreative, EffectFlip, EffectFade, EffectCube, EffectCoverflow]}
                spaceBetween={10}
                slidesPerView={4}
                navigation
                pagination={{ clickable: true, type: 'fraction', }}
                onSwiper={(swiper) => console.log("onswiper")}
                loop={true} 
                speed={2000}
                effect={"slide"}
                breakpoints={{
                  700: { slidesPerView: 1, },
                  1000: { slidesPerView: 1.2, },
                  1500: { slidesPerView: 2.2, },
                  1700: { slidesPerView: 4.2, },
                }}
              >
                {configControl.mural_model_card == "modelo01" &&
                  <>
                    <SwiperSlide>
                      <CardMural01 item={{ name: "João Silva", img: "https://img.freepik.com/free-photo/portrait-outdoors-successful-business-person_23-2148763862.jpg", msg: language == "es" ? msgEs[0] : msg[0] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural01 item={{ name: "Maria Santos", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[1] : msg[1] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural01 item={{ name: "Ana Oliveira", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[2] : msg[2] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural01 item={{ name: "Lucas Martins", img: "https://img.freepik.com/free-photo/portrait-outdoors-successful-business-person_23-2148763862.jpg", msg: language == "es" ? msgEs[8] : msg[8] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural01 item={{ name: "Juliana Costa", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[4] : msg[4] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural01 item={{ name: "Fernanda Souza", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[7] : msg[7] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural01 item={{ name: "Camila Almeida", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[6] : msg[6] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural01 item={{ name: "Rafael Lima", img: "https://img.freepik.com/free-photo/portrait-outdoors-successful-business-person_23-2148763862.jpg", msg: language == "es" ? msgEs[5] : msg[5] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural01 item={{ name: "Pedro Pereira", img: "https://img.freepik.com/free-photo/portrait-outdoors-successful-business-person_23-2148763862.jpg", msg: language == "es" ? msgEs[3] : msg[3] }} />
                    </SwiperSlide>
                  </>}

                {configControl.mural_model_card == "modelo02" &&
                  <>
                    <SwiperSlide>
                      <CardMural02 item={{ name: "João Silva", img: "https://img.freepik.com/free-photo/portrait-outdoors-successful-business-person_23-2148763862.jpg", msg: language == "es" ? msgEs[0] : msg[0] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural02 item={{ name: "Maria Santos", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[1] : msg[1] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural02 item={{ name: "Ana Oliveira", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[2] : msg[2] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural02 item={{ name: "Lucas Martins", img: "https://img.freepik.com/free-photo/portrait-outdoors-successful-business-person_23-2148763862.jpg", msg: language == "es" ? msgEs[8] : msg[8] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural02 item={{ name: "Juliana Costa", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[4] : msg[4] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural02 item={{ name: "Fernanda Souza", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[7] : msg[7] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural02 item={{ name: "Camila Almeida", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[6] : msg[6] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural02 item={{ name: "Rafael Lima", img: "https://img.freepik.com/free-photo/portrait-outdoors-successful-business-person_23-2148763862.jpg", msg: language == "es" ? msgEs[5] : msg[5] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural02 item={{ name: "Pedro Pereira", img: "https://img.freepik.com/free-photo/portrait-outdoors-successful-business-person_23-2148763862.jpg", msg: language == "es" ? msgEs[3] : msg[3] }} />
                    </SwiperSlide>
                  </>}

                  {configControl.mural_model_card == "modelo03" &&
                  <>
                    <SwiperSlide>
                      <CardMural03 item={{ name: "João Silva", img: "https://img.freepik.com/free-photo/portrait-outdoors-successful-business-person_23-2148763862.jpg", msg: language == "es" ? msgEs[0] : msg[0] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural03 item={{ name: "Maria Santos", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[1] : msg[1] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural03 item={{ name: "Ana Oliveira", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[2] : msg[2] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural03 item={{ name: "Lucas Martins", img: "https://img.freepik.com/free-photo/portrait-outdoors-successful-business-person_23-2148763862.jpg", msg: language == "es" ? msgEs[8] : msg[8] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural03 item={{ name: "Juliana Costa", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[4] : msg[4] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural03 item={{ name: "Fernanda Souza", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[7] : msg[7] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural03 item={{ name: "Camila Almeida", img: "https://st4.depositphotos.com/1049680/30011/i/450/depositphotos_300110826-stock-photo-african-american-business-executive-woman.jpg", msg: language == "es" ? msgEs[6] : msg[6] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural03 item={{ name: "Rafael Lima", img: "https://img.freepik.com/free-photo/portrait-outdoors-successful-business-person_23-2148763862.jpg", msg: language == "es" ? msgEs[5] : msg[5] }} />
                    </SwiperSlide>

                    <SwiperSlide>
                      <CardMural03 item={{ name: "Pedro Pereira", img: "https://img.freepik.com/free-photo/portrait-outdoors-successful-business-person_23-2148763862.jpg", msg: language == "es" ? msgEs[3] : msg[3] }} />
                    </SwiperSlide>
                  </>}

              </Swiper>
            </BodyNew>
          </div>
        </ContainerNew > : null


  )
}

export default PreviewMural
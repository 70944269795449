import React from 'react'
import { BarConfig, BarOptions, CardAction, CardImg, CardIntegration, CardText, Container, Preview, } from './styled'
import { Title } from '../lazy-loading/styled'


const LazyLoadingIntegration = () => {
  return (
    <Container>
      <Title>
        <div />
        <span />
      </Title>
      {Array(20).fill(
        <CardIntegration>
          <CardImg />
          <CardText>
            <div />
            <span />
          </CardText>
          <CardAction />
        </CardIntegration>
      )}
    </Container>
  )
}

export default LazyLoadingIntegration
import React from 'react'
import { Animation, Container, Contents, Line, Search, Size, Table, TableContents, TableLine, TableLineItem, TableTitle, TableTitleItem, Time, Title, Top, Total } from './styled'


const LazyLoadingDash = () => {
    return (
        <Container>
            <Line>
                <Animation />
                <Search/>
            </Line>
            <Line>
                <Contents />
                {/* <Total/>
                <Size /> */}
            </Line>
            {/* <Line>
                <Time />
                <Top />
            </Line> */}
        </Container>
    )
}

export default LazyLoadingDash
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingLogo from '../../../../../components/Loading-logo';
import ItemTableProduct from '../item-table-product';
import Navbar from '../navbar';
import { useParams } from 'react-router-dom';
import LoadingLogoLilyNew from '../../../../../components/Loading-logo-lily-new';
import { ContainerLoading } from '../../../../reviews/pages/home-new/styled';
import LazyLoading from '../../../../../components/lazy-loading';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function TableProducts(props) {

    const products = useSelector((state) => state.reducerOrderProducts);
    const dispatch = useDispatch()
    const { id } = useParams();

    useEffect(() => {
        console.log("props", props)
    }, [props])

    useEffect(() => {

        dispatch({
            type: "SAGA_LIST_ORDER_PRODUCTS", payload: id
            // {
            // search: search.replace("#", ""),
            // perPage: perPage,
            // page: page,
            // iniDate: dateInicioFilter,
            // finDate: dateFimFilter,
            // user_token: JSON.parse(localStorage.getItem("depoimentos@login"))?.integration?.user_token
            //}
        })
    }, [])

    useEffect(() => {
        console.log("produtos:", products)
    }, [products])




    return (
        products.loading ?
            // <ContainerLoading>
            //     <LoadingLogoLilyNew />
            // </ContainerLoading> :
            <LazyLoading /> :
            <>
                <div className="px-4 sm:px-6 lg:px-8">
                    <Navbar order={products?.data?.order_number} />
                    <div style={{ marginBottom: "28px" }} className="sm:flex sm:items-center">
                        <div className="sm:flex-auto">
                            <h1 className="text-base font-semibold leading-6 text-gray-900">{"Produtos"}</h1>
                            <p className="mt-2 text-sm text-gray-700">
                                {"Vincule arquivos aos produtos disponíveis na sua loja."}
                            </p>
                        </div>
                    </div>

                    <div className="mt-0 flow-root">
                        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                <div className="relative">
                                    <table className="min-w-full table-fixed divide-y divide-gray-300 bg-white">
                                        <thead>
                                            <tr>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    <label style={{ display: "flex", width: "100%", justifyContent: "center" }}>
                                                        {"Imagem"}
                                                    </label>
                                                </th>
                                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                                    Nome
                                                </th>
                                                <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                                                    <span className="sr-only">Edit</span>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className="divide-y divide-gray-200 bg-white">
                                            {products?.data?.order_items?.map((item) => (
                                                <ItemTableProduct
                                                    classNames={classNames}
                                                    item={item} />
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <Pagination setPage={changePage} /> */}
            </>
    )
}
